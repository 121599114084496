import { useEffect, useRef } from 'react';

import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import { createInstance } from '@optimizely/optimizely-sdk/dist/optimizely.lite.min.js';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import Cookies from 'universal-cookie';

import ErrorBoundary from '../components/prescriptive/ErrorBoundary';
import { UserProvider } from '../context/UserContext';
import { get30Days } from '../helpers/date-helpers';
import useTranscend from '../hooks/useTranscend';
import { checkIsDevelopment } from '../lib/_helpers';
import { initDatadogRum } from '../lib/datadog';
import {
  ExperimentDecisions,
  fetchDataFile,
  getOptimizelyExperimentNames,
  getOptimizelyExperimentVariation
} from '../lib/optimizely';

// initialize datadog
initDatadogRum();

const isDevelopment = checkIsDevelopment();

const reinitNavDropdowns = () => {
  const dropdowns = document.querySelectorAll('.dropdown');
  for (const dropdown of dropdowns) {
    dropdown.classList.add('-disabled');
    setTimeout(() => dropdown.classList.remove('-disabled'), 100);
  }
};

const setCookie = (cookies, key, value) => {
  cookies.set(key, value, {
    domain: window.location.hostname,
    expires: get30Days(),
    path: '/',
  });
};

const checkOptimizelyForDecision = async (cookies) => {
  try {
    const allCookies = cookies.getAll();
    const allCookieKeys = Object.keys(allCookies);

    for (const cookieKey of allCookieKeys) {
      const featureFlagKey = cookieKey.match(/opt_(.*)_processing/)?.[1];

      if (featureFlagKey) {
        const experimentUserId = cookies.get(`opt_${featureFlagKey}_user`);
        const attributes = cookies.get(`opt_${featureFlagKey}_processing`);
        const _ga = cookies.get('_ga');

        if (attributes === 'false') {
          continue;
        }

        if (_ga) {
          attributes._ga = _ga;
        }

        cookies.set(`opt_${featureFlagKey}_processing`, false, { path: '/' });

        const datafile = await fetchDataFile(window.location.origin);

        if (!datafile) {
          return null;
        }

        const optimizelyClient = createInstance({
          datafile: JSON.stringify(datafile),

          // eslint-disable-next-line unicorn/prefer-module
          eventDispatcher: require('@optimizely/optimizely-sdk')
            .eventDispatcher,
        });

        const userContext = optimizelyClient.createUserContext(
          experimentUserId,
          attributes
        );

        const {
          flagKey, ruleKey, variationKey,
        } = userContext.decide(featureFlagKey);

        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
          event: 'optimizely-decision',
          'optimizely-flagKey': flagKey,
          'optimizely-ruleKey': ruleKey,
          'optimizely-userId': experimentUserId,
          'optimizely-variationKey': variationKey,
        });
      }
    }
  } catch (error) {
    console.log('e:', error);
  }
};

export default function App(props) {
  const { Component, pageProps } = props;
  const router = useRouter();
  const { current: cookies } = useRef(new Cookies());
  const isPreviousPageSava = router.query.sava === '1';

  useEffect(() => {
    if (!isPreviousPageSava) {
      window.sessionStorage.removeItem('saved_form_values');
    }
  }, [isPreviousPageSava]);

  useTranscend();

  useEffect(() => {
    const onMountLocation = window.location.pathname;

    const previousCookie = cookies.get('intial_landing_page');
    const newCookie = cookies.get('initial_landing_page');

    // set initial landing page cookie
    if (previousCookie) {
      // Use an existing (typo) cookie
      setCookie(cookies, 'initial_landing_page', previousCookie);
    } else if (!newCookie) {
      setCookie(cookies, 'initial_landing_page', onMountLocation);
    }

    checkOptimizelyForDecision(cookies);

    // set previous url
    setCookie(cookies, 'previous_url', onMountLocation);

    const handleRouteChangeStart = () => {
      setCookie(cookies, 'previous_url', window.location.pathname);
    };

    const handleRouteChangeComplete = () => {
      // closes our nav for us on route change...
      reinitNavDropdowns();
      checkOptimizelyForDecision(cookies);
    };

    document.addEventListener('gtmScrollTriggerFired', function(e) {
      const scrollCookieName = `opt_scroll_${e.detail.scrollDepth}`;
      const gtmScrollEventRegistered = cookies.get(scrollCookieName);

      if (!gtmScrollEventRegistered) {
        setCookie(cookies, scrollCookieName, true);

        const optimizelyExperimentNames = getOptimizelyExperimentNames();

        for (const experimentName of optimizelyExperimentNames) {
          const variation = getOptimizelyExperimentVariation(experimentName);

          if (variation !== ExperimentDecisions.OutOfExperiment && typeof window !== 'undefined') {
            fetch(`${window.location.origin}/api/www-vercel-optimizely-events?eventKeys=scroll_depth_${e.detail.scrollDepth}_reached`)
              .then((response) => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }

                return response.json();
              })
              .then((data) => {
                console.log(data);
              })
              .catch((error) => {
                console.error('Fetch error:', error);
              });
          }
        }
      }
    });

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  const {
    enableLivePreview, locale, session,
  } = pageProps;
  let AppContent = <Component {...pageProps} />;

  // only development builds get the session provider
  if (isDevelopment) {
    AppContent = (
      <SessionProvider session={session}>
        {AppContent}
      </SessionProvider>
    );

    if (enableLivePreview) {
      AppContent = (
        <ContentfulLivePreviewProvider
          enableInspectorMode
          enableLiveUpdates
          locale={locale}
        >
          {AppContent}
        </ContentfulLivePreviewProvider>
      );
    }
  }

  return (
    <ErrorBoundary name="global">
      <UserProvider>
        {AppContent}
      </UserProvider>
    </ErrorBoundary>
  );
}
